import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import tillDetails from './tillDetails/reducers'
import salesErrorDetails from './salesErrorDetails/reducers'
import companyDetails from './companyDetails/reducers'
import purchaseDetails from './purchaseDetails/reducers'
import businessDetails from './businessDetails/reducers'
import customerDetails from './customerDetails/reducers'
import usersDetails from './userDetails/reducers'
import productDetails from './productDetails/reducers'
import inventoryDetails from './inventoryDetails/reducers'
import goodsReceiptView from './listGoodsReceipts/reducers'
import newPurchaseView from './newPurchaseOrder/reducers'
import settings from './settings/reducers'

import windowDetails from './windowDetails/reducers'
import createWindow from './createWindow/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    tillDetails,
    companyDetails,
    inventoryDetails,
    purchaseDetails,
    salesErrorDetails,
    businessDetails,
    usersDetails,
    customerDetails,
    productDetails,
    windowDetails,
    createWindow,
    goodsReceiptView,
    newPurchaseView,
  })
