const initialState = {
  createPoDetails: [],
}

export default function goodsReceiptView(state = initialState, action) {
  if (action.type === 'Create_PoDetails') {
    return {
      ...state,
      createPoDetails: action.payload,
    }
  }
  return state
}
