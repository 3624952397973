
 const initialState = {
  inventoryDetails:'',
  stockTransferConfigDetails:'', 
  stockTransferRequestDetails:'',
  stockTransferIssueDetails:''
} 

export default function productListView( state = initialState, action) {
if(action.type==='Physical_Inventory_Details'){
    return {
      ...state,
      inventoryDetails:action.payload,
    }
  }
  if(action.type==='StockTransfer_Config'){
    return {
      ...state,
      stockTransferConfigDetails:action.payload,
    }
  }
  if(action.type==='STR_Details'){
    return {
      ...state,
      stockTransferRequestDetails:action.payload,
    }
  }

  if(action.type==='STI_Details'){
    return {
      ...state,
      stockTransferIssueDetails:action.payload,
    }
  }
  return state; 

}


