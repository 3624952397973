/* eslint-disable */

import React from 'react'
import { createHashHistory } from 'history'
import { withRouter } from 'react-router-dom'


const history = createHashHistory()
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: '' };
  }

  componentDidCatch(error, errorInfo) {
    localStorage.setItem('runTimeError', error)
    history.push('/404/error')
    this.setState({ hasError: true, errorDetails: error });
  }

  redirectToListWindow() {
    if (window.location.hash === '#/windows/windowList') {
      history.push('/CW/home')
      location.reload();
    } else {
      history.push('/windows/windowList')
      location.reload();
    }

  }

  render() {
    const errorData = localStorage.getItem('runTimeError')
    if (this.state.hasError) {
      return (
        <div />
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)
