
const initialState = {
  businessDetails:'' 
}

export default function productListView(state = initialState, action) {
  if(action.type==='Business_Details'){
    return {
      ...state,
      businessDetails:action.payload,
    }
  }
  return state;

}