
 const initialState = {
  tillDetails:'' 
} 

export default function tillListView( state = initialState, action) {
if(action.type==='Till_Details'){
    return {
      ...state,
      tillDetails:action.payload,
    }
  }
  return state; 

}


