import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'
// import footerImg from '../../../assets/img/CW_LOGO.jpg'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-sm-12">
              <div className={style.footerServicesChildLogo}>
                <div><span>Copyright © 2021 CWERP Solutions Pvt Ltd.</span><span>Version : 1.0.1</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
