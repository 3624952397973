

const initialState = {
  ad_window_id:''
  }

export default function sendWindowId(state = initialState, action) {

   if(action.type==='SEND_AD_WINDOW_ID'){
    return {
      ...state,
      ad_window_id:action.payload,
    }
  }
  
  return state
}