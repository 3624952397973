/* eslint-disable */

import React from 'react'
import { AppstoreOutlined,SettingOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Popover, Input, Select, message } from 'antd'
import ApolloClient from 'apollo-boost'
import gql from 'graphql-tag'
import axios from 'axios'
import SearchBar from 'components/layout/TopBar/Search/index.js'
// import clientConfigurations from '../../../pages/clientWindowConfig.json'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { history } from 'index'
import { changeSelectedOption } from '../../../redux/menu/actions'
import { URL, GENERICURL } from '../../../pages/app-config/index'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import '../../../customcss/style.css'

const { SubMenu } = Menu
const { Option, OptGroup } = Select
const mapStateToProps = ({ user, menu }) => {
  return {
    Token: user.token,
    RoleInfo: menu.roleInfo,
  }
}

// console.log("clientConfigurations===>",clientConfigurations)

@connect(mapStateToProps)
class TopBar extends React.Component {
  state = {
    dropdownData: [],
    menuDataForService: [],
    defaultValueToSet: 'Add to Favourites',
    favMenuDada: [],
    dashboardMenuData: [],
    reportMenuData: [],
    newReportMenuData: [],
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.RoleInfo.length == 0) {
    } else {
      console.log('else')
    }
  }

  componentDidMount = () => {
    this.getMessages()
    this.getGlobalParameters()
    this.getfavouriteAddedMenuByUser()
    this.getLeftMenus()
  }

  getLeftMenus = () => {
    const roleId = localStorage.getItem('roleId')
    const newToken = localStorage.getItem('Token')
    const getMenusData = {
      query: `query {
          get360MenuList(applicationId:"0", roleId:"${roleId}"){
            menu
            cwLogo
            clientLogo
          }
      }`,
    }

    axios({
      url: GENERICURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getMenusData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      const menusData = JSON.parse(response.data.data.get360MenuList.menu)
      const cwLogoFromServer = response.data.data.get360MenuList.cwLogo
      const clientLogoFromServer = response.data.data.get360MenuList.clientLogo
      this.setState({menuDataForService:menusData,cwLogoInState:cwLogoFromServer,clientLogoInState:clientLogoFromServer})
      const DrData = menusData !== null || menusData !== null ? menusData :[]
      let dashboardData = []
      let reportData = []
      const mainArray = []
      const mainTwo = []
      for (let index = 0; index < DrData.length; index++) {
        const elementOne = DrData[index]
        const elementWithChildren = elementOne.children !=undefined ? elementOne.children:''
        const childrenArray = []
        for (let index1 = 0; index1 < elementWithChildren.length; index1++) {
          const elementTwo = elementWithChildren[index1]
          if (elementTwo.type === 'Report') {
            childrenArray.push(elementTwo)
          }
        }
        mainArray.push({
          title: DrData[index].title,
          key: DrData[index].key,
          children: childrenArray,
        })
      }
      for (let index = 0; index < mainArray.length; index++) {
        const element = mainArray[index].children
        if (element.length > 0) {
          mainTwo.push(mainArray[index])
        }
      }
      this.setState({ newReportMenuData: mainTwo })

      for (let index = 0; index < DrData.length; index++) {
        const element = DrData[index].children
        if(element!==undefined){
        for (let index = 0; index < element.length; index++) {
          const windowType = element[index].type
          if (windowType === 'Dashboard') {
            dashboardData.push(element[index])
          } else if (windowType === 'Report') {
            reportData.push(element[index])
          }
          this.setState({dashboardMenuData: dashboardData, reportMenuData: reportData })
        }
      }
      }
    })
  }

  getAdminMenu = () =>{
    const roleId = localStorage.getItem('roleId')
    window.localStorage.setItem('isSideMenu',"Y")
    const newToken = localStorage.getItem('Token')
    const getMenusData = {
      query: `query {
        getAdminMenuList(roleId:"${roleId}"){
          menu
          cwLogo
          clientLogo
      }   
      }`,
    }

    axios({
      url: GENERICURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getMenusData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      const selectedmenu = response.data.data.getAdminMenuList.menu
      const cwLogoFromServer = response.data.data.getAdminMenuList.cwLogo
      const clientLogoFromServer = response.data.data.getAdminMenuList.clientLogo
        localStorage.setItem('flagforselectedmenu',2)
        localStorage.setItem('selectedmenu',JSON.stringify(selectedmenu))
        localStorage.setItem('cwLogo',cwLogoFromServer)
        localStorage.setItem('clientLogo',clientLogoFromServer)
        history.push('/CW/home')
        window.location.reload();
    })
  }

  getMessages = () => {
    const reduxToken = localStorage.getItem('Token')
    const client = new ApolloClient({
      uri: GENERICURL,
      request: operation => {
        operation.setContext({
          headers: {
            authorization: `bearer ${reduxToken}`,
          },
        })
      },
    })

    const GET_MESSAGES = gql`
      query {
        getMessages
      }
    `
    client
      .query({
        query: GET_MESSAGES,
      })
      .then(result => {
        window.localStorage.setItem('AllMessages', result.data.getMessages)
      })
      .catch(err => {
        const error = JSON.parse(JSON.stringify(err))
        const { message } = error
        if (message === 'Network error: Unexpected token < in JSON at position 0') {
          history.push('/system/login')
          //  window.location.reload();
        }
      })
  }

  getGlobalParameters = () => {
    const reduxToken = localStorage.getItem('Token')
    const client = new ApolloClient({
      uri: GENERICURL,
      request: operation => {
        operation.setContext({
          headers: {
            authorization: `bearer ${reduxToken}`,
          },
        })
      },
    })

    const GET_MESSAGES = gql`
      query {
        getGlobalParameters
      }
    `
    client
      .query({
        query: GET_MESSAGES,
      })
      .then(result => {
        window.localStorage.setItem('AllGlobalParameters', result.data.getGlobalParameters)
      })
      .catch(err => {
        const error = JSON.parse(JSON.stringify(err))
        const { message } = error
        if (message === 'Network error: Unexpected token < in JSON at position 0') {
          history.push('/system/login')
          //  window.location.reload();
        }
      })
  }

  hello1 = (windowid, url, type) => {
    const { dispatch } = this.props
    if (type == 'Generic') {
      dispatch(changeSelectedOption({ selectedOption: windowid }))
    // window.localStorage.setItem('adWinId', windowid)
      let genericUrl = '/window/list/'.concat(`${windowid}`)
      history.push(`${genericUrl}`)
    } else if (type === 'Report') {
      dispatch(changeSelectedOption({ selectedOption: windowid }))
    //  window.localStorage.setItem('adWinId', windowid)
      const reportUrl = '/reports/reportsList/'.concat(`${windowid}`)
      history.push(`${reportUrl}`)
    } else if (type === 'Dashboard') {
      dispatch(changeSelectedOption({ selectedOption: windowid }))
    //  window.localStorage.setItem('adWinId', windowid)
      const dashboardUrl = '/dashboards/dashboardsList/'.concat(`${windowid}`)
      history.push(`${dashboardUrl}`)
    } else {
// window.localStorage.setItem('adWinId', windowid)
      const customUrl = url
      history.push(`${customUrl}`)
    }
  }

  toSendRequest1 = (id, title, url, type) => {
    this.setState({ defaultValueToSet: title })
    const clientId = window.localStorage.getItem('ClientId')
    const menuId = id
    const menuName = title
    const menuType = type
    const newToken = localStorage.getItem('Token')
    this.setState({ reduxToken: newToken, loading: true })
    const getFavMenuAdd = {
      query: `mutation {upsertCsFavorites (csFavorites: {id:null
        clientId: "${clientId}"
        menuId : "${menuId}"
        menuName :"${menuName}"
        type:"${menuType}"
        url:"${url}"})
     {messageCode             
      title             
      message}}`,
    }
    axios({
      url: GENERICURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getFavMenuAdd,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      if (response.data.data.upsertCsFavorites.title === 'Success') {
        message.success(response.data.data.upsertCsFavorites.message)
        this.getfavouriteAddedMenuByUser()
      } else {
        message.error(response.data.data.upsertCsFavorites.message)
      }
    })
  }

  getfavouriteAddedMenuByUser = () => {
    const newToken = localStorage.getItem('Token')
    const getFavMenuAddedbyUser = {
      query: `query {getFavorites 
        {
          id,
          clientId,
          userId,
          menuId,
          menuName,
          type,
          url
        }
       }`,
    }
    axios({
      url: GENERICURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getFavMenuAddedbyUser,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      this.setState({ favMenuDada: response.data.data.getFavorites })
      const favd = response.data.data.getFavorites
      window.localStorage.setItem('f', JSON.stringify(favd))
    })
  }

  handleReportMenuClick = e => {
    const windowid = e.item.props.k.id
    const { dispatch } = this.props
    dispatch(changeSelectedOption({ selectedOption: windowid }))
   // window.localStorage.setItem('adWinId', windowid)
    window.localStorage.setItem('recordId', undefined)
    window.location = '/#/reports/reportsList/'.concat(`${windowid}`)
  }

  handleDashboardMenuClick = e => {
    const windowid = e.item.props.title.id
    const { dispatch } = this.props
    dispatch(changeSelectedOption({ selectedOption: windowid }))
   // window.localStorage.setItem('adWinId', windowid)
    window.localStorage.setItem('recordId', undefined)
    window.location = '/#/dashboards/dashboardsList/'.concat(`${windowid}`)
  }
  deleteFavourites = id => {
    const newToken = localStorage.getItem('Token')
    const deleteMenu = {
      query: `mutation {
        deleteCsFavorites (favoriteId : "${id}") {
        messageCode
        title
        message
        }}`,
    }
    axios({
      url: GENERICURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: deleteMenu,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      message.success(response.data.data.deleteCsFavorites.message)
      this.getfavouriteAddedMenuByUser()
    })
  }

  hideSideMenu=()=>{
    window.location.reload();
    window.localStorage.setItem('isSideMenu',"N")
  }

  render() {
    const {
      newReportMenuData,
      menuDataForService,
      defaultValueToSet,
      favMenuDada,
      dashboardMenuData,
      clientLogoInState,
      cwLogoInState
    } = this.state

    // console.log("====Is Admin==",window.localStorage.getItem('issAdmin'))
    const isAdmin = window.localStorage.getItem('issAdmin')
    const sideMenu=window.localStorage.getItem('isSideMenu')
    const UserName = window.localStorage.getItem('User')
    const clientConfigurations = JSON.parse(window.localStorage.getItem('CW360UIConfigurations'))
    
    let menuList=''
    if(menuDataForService!==null){
       menuList = menuDataForService.map(menu => {
        if(menu.children!==undefined){
        if (menu.children.length === 0) {
          return (
            <OptGroup
              label={
                <span style={{ fontSize: '13px', color: 'black' }}>
                  <strong>{menu.title}</strong>
                </span>
              }
            >
              <Option
                key={menu.id}
                value={menu.title}
                onClick={() => this.toSendRequest1(menu.id, menu.title, menu.url, menu.type)}
              >
                <Link>
                  <span style={{ color: '#314659' }}>{menu.title}</span>
                </Link>
              </Option>
            </OptGroup>
          )
        } else {
          return (
            <OptGroup
              label={
                <span style={{ fontSize: '13px', color: 'black' }}>
                  <strong>{menu.title}</strong>
                </span>
              }
            >
              {menu.children.map(sub => {
                return (
                  <Option
                    key={sub.id}
                    value={sub.title}
                    onClick={() => this.toSendRequest1(sub.id, sub.title, sub.url, sub.type)}
                  >
                    <Link>
                      <span style={{ color: '#314659' }}>{sub.title}</span>
                    </Link>
                  </Option>
                )
              })}
            </OptGroup>
          )
        }
      }
      })
    }
    


    const menufav = (
      <div>
        <Select
          showSearch
          value={
            <span>
              <span>
                <i className={`${style.searchIcon} fa fa-search`} />
              </span>
              <span className={style.searchBarStyleValue}>{defaultValueToSet}</span>
            </span>
          }
          allowClear={false}
          // notFoundContent={fetching ? <Spin size="small" /> : null}
          showArrow={false}
          style={{ marginTop: '3px', fontFamily: 'Open Sans' }}
          className={style.genericQuickSearchboxAboveAgGrid}
        >
          {menuList}
        </Select>
        <p />
        <ul className={style.Ul_style}>
          {favMenuDada.map(data => {
            return (
              <li>
                <div className="row">
                  <div className="col-lg-9">
                    <a
                      role="presentation"
                      onClick={() => this.hello1(data.menuId, data.url, data.type)}
                      style={{ color: '#080707', fontSize: '14px', fontFamily: 'Open Sans' }}
                    >
                      {data.menuName}
                    </a>
                  </div>
                  <div className="col-lg-3">
                    <img
                      src="resources/images/Yellowpin.svg"
                      alt="pic"
                      role="presentation"
                      style={{ marginLeft: '30%', cursor: 'pointer' }}
                      onClick={() => this.deleteFavourites(data.id)}
                    />
                  </div>
                </div>
                <p />
              </li>
            )
          })}
        </ul>
      </div>
    )

    const reportMenu = (
      <div className="card" style={{border:'none',marginBottom:'0px'}}>
        <div className="card-body" style={{ padding: '0px',width:'250px' }}>
        <Menu onClick={this.handleReportMenuClick} style={{ width: '100%',border:'none' }} mode="inline">
            {newReportMenuData.map(data => (
              <SubMenu key={data.key} title={data.title}>
                {data.children.map(childata => {
                  return (
                    <Menu.Item key={childata.key} k={childata}>
                      {childata.title}
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            ))}
        </Menu>
      </div>
      </div>
    )

    const dashboardMenu = (
      <Menu onClick={this.handleDashboardMenuClick}>
        {dashboardMenuData.map(data => {
          return (
            <Menu.Item key={data.key} title={data} style={{ color: '#314659' }}>
              {data.title}
            </Menu.Item>
          )
        })}
      </Menu>
    )
    return (
      <div
        className={style.topbar}
        style={{
          backgroundColor:
            clientConfigurations === null ? '#ffffff' : clientConfigurations.topBarColor,
        }}
      >
        {sideMenu === 'N' ? (
          <div className="mr-md-4 mr-auto">
            <img
              // className={style.hide}
              // style={{ width: '140px',height: '38px', marginTop: '2px' }}
              style={{
                objectFit: 'cover',
                maxWidth: '120px',
                maxHeight: '120px',
                marginTop:'4px'
              }}
              src={
                cwLogoInState !== null
                  ? `data:image/jpeg;base64,${cwLogoInState}`
                  : 'resources/images/cwsolutionslogo.png'
              }
              // src="resources/images/cwanalytics.png"
              alt="CW"
              onClick={this.getClicked}
            />
          </div>
        ) : (
          <div className="mr-md-4 mr-auto" />
        )}
        {clientLogoInState !== null ? (
          <div className="mr-auto d-none d-md-block" style={{ width: '100px' }}>
            <img
              className={style.hide}
              style={{
                objectFit: 'cover',
                maxWidth: '120px',
                maxHeight: '120px',
              }}
              // style={{ width: '40px', height: '40px' }}
              src={
                clientLogoInState !== null
                  ? `data:image/jpeg;base64,${clientLogoInState}`
                  : 'resources/images/ofc.png'
              }
              // src="resources/images/ofc.png"
              width="40px"
              alt="CW"
            />
          </div>
        ) : (
          <div className="mr-auto d-none d-md-block" />
        )}
        {sideMenu === 'N' ? (
          <div className="mr-4 d-none d-sm-block">
            <SearchBar />
          </div>
        ) : (
          <div className="mr-auto d-none d-md-block" />
        )}
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" style={{ marginTop: '4px' }} />
        <div className="mr-4 d-none d-sm-block">
          <Popover
            placement="bottom"
            /* title={text} */ style={{ marginLeft: '120px' }}
            content={menufav}
            trigger="hover"
          >
            <strong>
              <img
                className={style.heartIcon}
                src={clientConfigurations === null ? '' : clientConfigurations.favouriteIcon}
                alt="search"
              />
            </strong>
          </Popover>
        </div>
        <div className="mr-4 d-none d-sm-block">
          <Popover
            placement="bottomRight"
            style={{ marginLeft: '80px', padding: '0px' }}
            content={reportMenu}
            trigger="hover"
          >
            <strong>
              <img
                className={style.reportIcon}
                src={clientConfigurations === null ? '' : clientConfigurations.reportIcon}
                alt="report"
              />
            </strong>
          </Popover>
        </div>
        <div className="mr-4 d-none d-sm-block">
          <Dropdown overlay={dashboardMenu} trigger={['hover']}>
            <strong>
              <img
                className={style.dashboardIcon}
                src={clientConfigurations === null ? '' : clientConfigurations.dashboardIcon}
                alt="dashbord"
              />
            </strong>
          </Dropdown>
        </div>
        {isAdmin === 'Y' ? (
          <div className={`mr-4 d-none d-sm-block ${style.bgColor}`}>
            {/* <div>
              <strong>
                <img
                  className={style.settingsIcon}
                  src='resources/images/settings-24px.svg'
                  alt="settings"
                  style={{ cursor: 'pointer' }}
                  onClick={this.getAdminMenu}
                />
              </strong>
            </div> */}

            <SettingOutlined
              style={{
                cursor: 'pointer',
                color: clientConfigurations === null ? '' : clientConfigurations.settingIconColor,
                fontSize: '18px',
                marginTop: '8px',
              }}
              onClick={this.getAdminMenu}
            />

            {/* <SettingOutlined
                style={{
                  cursor: 'pointer',
                  color: clientConfigurations.settingIconColor,
                  fontSize: '18px',
                  marginTop: '8px',
                }}
                onClick={this.hideSideMenu}
              /> */}
          </div>
        ) : (
          ''
        )}
        {UserName === 'CW OFC' && sideMenu === 'Y' ? (
          <div className="mr-4 d-none d-sm-block">
            <span style={{ cursor: 'pointer', color: '#5A5A5A' }} onClick={this.hideSideMenu}>
              Hide Side Menu
            </span>
          </div>
        ) : (
          ''
        )}
        <div>
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default withRouter(TopBar)

