/* eslint-disable */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
// import configurationJSON from 'pages/clientWindowConfig.json'
import { Menu, Dropdown, Form, Button, Select,Divider,Popover } from 'antd'
import { GENERICURL } from '../../../../pages/app-config/index'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 7,
      visible: false,
      listOfRoles: [],
      listOfBusinessUnit: [],
    }
  }

  componentWillMount() {
    this.setState({ listOfRoles: [], listOfBusinessUnit: [] })
    const reduxToken = localStorage.getItem('Token')
    const UserId = localStorage.getItem('UserId')
    const self = this
    const getRoles = {
      query: `query {comboFill(tableName:"cs_role", pkName:"cs_role_id", identifier:"name", whereClause:"cs_role_id in (select cs_role_id from cs_user_access where cs_user_id = '${UserId}')")}`,
    }
    Axios({
      url: GENERICURL,
      method: 'POST',
      data: getRoles,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${reduxToken}`,
      },
    })
      .then(response => {
        const getSearchData = JSON.parse(response.data.data.comboFill)
        if (response.data.data.comboFill !== null) {
          const getSearchDisplayData = getSearchData
          self.setState({ listOfRoles: getSearchDisplayData })
        } else {
          self.setState({ listOfRoles: [] })
        }
      })
      .catch(error => {
        console.log(error)
      })

    const getBusinessUnit = {
      query: `query {comboFill(tableName:"cs_bunit", pkName:"cs_bunit_id",identifier:"name", whereClause:"cs_bunit_id in (select cs_bunit_trx_id from cs_user_bunit where cs_user_id = '${UserId}')")}`,
    }
    Axios({
      url: GENERICURL,
      method: 'POST',
      data: getBusinessUnit,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${reduxToken}`,
      },
    })
      .then(response => {
        const getSearchData = JSON.parse(response.data.data.comboFill)
        if (response.data.data.comboFill !== null) {
          const getSearchDisplayData = getSearchData

          self.setState({ listOfBusinessUnit: getSearchDisplayData })
        } else {
          self.setState({ listOfBusinessUnit: [] })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  handleVisibleChange = flag => {
    const { form } = this.props
    const BUId = window.localStorage.getItem('BuId')
    const RoleId = window.localStorage.getItem('roleId')
    this.setState({ visible: flag })

    form.setFieldsValue({
      roles: RoleId,
      bunit: BUId,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { changeRoleForApp, history } = this.props
    const reduxToken = localStorage.getItem('Token')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const sendRoleIdAndBuToServer = {
          query: `mutation {
            updateRoleAndBunitId(roleId:"${values.roles}", bunitId:"${values.bunit}") {
                title, 
                messageCode, 
                message
            }
        }
        `,
        }
        Axios({
          url: GENERICURL,
          method: 'POST',
          data: sendRoleIdAndBuToServer,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${reduxToken}`,
          },
        })
          .then(response => {
            if(response.data.data.updateRoleAndBunitId.messageCode==200){
              window.localStorage.setItem('roleId', values.roles)
             changeRoleForApp({ changeRoleForApp: { RoleId: values.roles, BusinessUnit: values.bunit } })
             localStorage.removeItem('item')
             history.push('/CW/home')
             window.location.reload(); 
            }
            
          })
          .catch(error => {
            console.log(error)
          })

      
      }
    })
  }

  getProfile = () =>{
    const { history } = this.props
    history.push('/CW/profile')
  }

  render() {

    const { user, form } = this.props
    const { listOfRoles, listOfBusinessUnit } = this.state
    const Email = window.localStorage.getItem('email')
    const RoleName = window.localStorage.getItem('roleName')
    const RoleId = window.localStorage.getItem('roleId')

    const BUId = window.localStorage.getItem('BuId')

    const PhoneNo = window.localStorage.getItem('PhoneNo')
    const UserName = window.localStorage.getItem('User')
    const sideMenu=window.localStorage.getItem('isSideMenu')
    const clientConfigurations = JSON.parse(window.localStorage.getItem('CW360UIConfigurations'))

    const { getFieldDecorator } = form

    let optionsByDefault = listOfRoles

    let optionsByDefaultForBu = listOfBusinessUnit

    const menu = (
      <div className={`${styles.cardstyle} card`}>
        <div className="card-body" style={{ padding: '0px' }}>
        <div className="row" style={{cursor:'pointer'}} onClick={this.getProfile}>
              <div className="col-lg-3">
                <img
                  src="../resources/images/blankImage.png"
                  alt="user"
                  style={{ borderRadius: '37px',height:'55px',width:'55px' }}
                />
              </div>
              <div className="col-lg-9" style={{ padding: '10%',paddingTop:'3%' }}>
                <div className="row">
                  <div className="col-lg-12">
                    <h5 style={{ fontSize: '14px', fontWeight: '600' }}>{UserName}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h6 style={{fontSize: '10px', color: '#4E4E4E' }}>{Email ==='null'?'':Email}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h6 style={{fontSize: '10px', color: '#4E4E4E' }}>{PhoneNo ==='null'? '':PhoneNo}</h6>
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{marginBottom:'0px',marginTop:'0px',height:'1px'}} />
        <Form layout="vertical" style={{ width: '20rem' }} onSubmit={this.handleSubmit}>
        <div className="row" style={{ padding: '10px' }}>
          <div className="col-lg-12">
            <Form.Item label="Roles">
              {getFieldDecorator('roles', {
                initialValue: RoleId,
              })(
                <Select
                  showSearch
                  style={{ width: 200 }}
                  className="certain-category-search"
                  style={{ width: '100%' }}
                  className="certain-category-search"
                  dropdownClassName="certain-category-search-dropdown"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    (optionsByDefault = listOfRoles.map(group => (
                      <Option key={group.recordid} value={group.recordid}>
                        {group.name}
                      </Option>
                    )))
                  }
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item label="Business Unit">
              {getFieldDecorator('bunit', {
                initialValue: BUId,
              })(
                <Select
                  showSearch
                  style={{ width: 200 }}
                  className="certain-category-search"
                  style={{ width: '100%' }}
                  className="certain-category-search"
                  dropdownClassName="certain-category-search-dropdown"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    (optionsByDefaultForBu = listOfBusinessUnit.map(group => (
                      <Option key={group.recordid} value={group.recordid}>
                        {group.name}
                      </Option>
                    )))
                  }
                </Select>,
              )}
            </Form.Item>
          </div>
        </div>
        <Form.Item style={{float:'right'}}>
           <Button style={{ margin: '0px 0px 0px 12px' ,width:'93px',height:'33px',color:'#5D5454',fontWeight:'600',fontSize:'14px',border:'none' }}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ margin: '0px 0px 0px 12px',backgroundColor:'#089EA4',border:'0.5px solid #07888D',borderRadius:'0.5px',width:'93px',height:'33px',fontWeight:'600',color:'#ffffff',fontSize:'14px' }}>
            Apply
          </Button>
        </Form.Item>
        <Divider style={{marginBottom:'10px',marginTop:'0px',height:'1px'}} />
            <a href="#" onClick={this.logout}>
              <i className={`${styles.menuIcon} fe fe-log-out`} />
              <span style={{color:'#5D5454',fontSize:'14px'}}>Logout</span>
            </a>
      </Form>
      </div>
      </div>
    )
    return (
      <Popover content={menu} trigger="hover" placement="bottomRight">
        <div className={styles.dropdown}>
          <span style={{fontWeight:'bold',marginTop:'2px',color:clientConfigurations===null ? '' : clientConfigurations.userMenuColor}}>{UserName}</span>
        </div>
        </Popover>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeRoleForApp: data => {
      dispatch({ type: 'CHANGE_ROLE_FOR_APP', payload: data })
    },
  }
}

const ProfileMenus = Form.create()(ProfileMenu)
export default connect(null, mapDispatchToProps)(withRouter(ProfileMenus))
