const initialState = {
  NewPurchaseDetails: [],
}

export default function newPurchaseView(state = initialState, action) {
  if (action.type === 'New_Purchase_Details') {
    return {
      ...state,
      NewPurchaseDetails: action.payload,
    }
  }
  return state
}
