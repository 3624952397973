
 const initialState = {
  usersDetails:'' 
} 

export default function productListView( state = initialState, action) {
if(action.type==='Users_Details'){
    return {
      ...state,
      usersDetails:action.payload,
    }
  }
  return state; 

}


