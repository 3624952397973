
 const initialState = {
  purchaseDetails:'',
  GoodsReciptViewData:'',
  purchaseOrderSubTotal:0,
  updatedRowJson:[],
  AddOrgIdForTax:'',
  AddDefaultBuisnessLocationId:'',
  DisplayAddProductBtn_1:'',
  DisplayAddProductBtn_2:'',
  DisplayAddProductBtn_3:'',
  CalBaseOnInOrEx:''

} 

export default function productListView( state = initialState, action) {
if(action.type==='Purchase_Details'){
    return {
      ...state,
      purchaseDetails:action.payload,
    }
  }
  if(action.type==='CreatePurchaseOrder_Total'){
    return {
      ...state,
      purchaseOrderSubTotal:action.payload,
    }
  }
  if(action.type==='SaveUpdatedRowData'){
    return {
      ...state,
      updatedRowJson:action.payload,
    }
  }
    if(action.type==='Add_OrgId'){
      return {
        ...state,
        AddOrgIdForTax:action.payload,
      }

  }
  if(action.type==='Add_Default_BUID'){
    return {
      ...state,
      AddDefaultBuisnessLocationId:action.payload,
    }
    

  
}
if(action.type==='Display_Add_Product_1'){
  return {
    ...state,
    DisplayAddProductBtn_1:action.payload,
  }
  

}

if(action.type==='Display_Add_Product_2'){
  return {
    ...state,
    DisplayAddProductBtn_2:action.payload,
  }
  

}

if(action.type==='Display_Add_Product_3'){
  return {
    ...state,
    DisplayAddProductBtn_3:action.payload,
  }
  

}
if(action.type==='Cal_Base_On_In_Or_Ex'){
  return {
    ...state,
    CalBaseOnInOrEx:action.payload,
  }
  

}

if(action.type==='Goods_Recipt_Details'){
  return {
    ...state,
    GoodsReciptViewData:action.payload,
  }
  

}



  return state; 

}


