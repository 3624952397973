
 const initialState = {
  customerDetails:'' 
} 

export default function productListView( state = initialState, action) {
if(action.type==='Customer_Details'){
    return {
      ...state,
      customerDetails:action.payload,
    }
  }
  return state; 

}


