import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import ErrorBounderies from 'pages/system/Errors/errorBoundaries'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },

  {
    path: '/404/error',
    Component: loadable(() => import('pages/CW/404Page/Errors/index.js')),
    exact: true,
  },

  // ================= Home Start ================= //

  {
    path: '/CW/home',
    Component: loadable(() => import('pages/CW/home')),
    exact: true,
  },
  {
    path: '/products/createProduct',
    Component: loadable(() => import('pages/CW/products/master/createProducts')),
    exact: true,
  },
  {
    path: '/products/productsList',
    Component: loadable(() => import('pages/CW/products/master/listProducts')),
    exact: true,
  },
  {
    path: '/products/listCatalog',
    Component: loadable(() => import('pages/CW/products/catalogue/listCatalogue')),
    exact: true,
  },

  {
    path: '/window/list/:id',
    Component: loadable(() => import('pages/WINDOWS')),
    exact: true,
  },
  {
    path: '/window/form/:id/:recordId',
    Component: loadable(() => import('pages/WINDOWS/detailsOfWindow/')),
    exact: true,
  },

  {
    path: '/setup/createCustomer',
    Component: loadable(() => import('pages/CW/setup/customer/createCustomer')),
    exact: true,
  },
  {
    path: '/setup/listCustomer',
    Component: loadable(() => import('pages/CW/setup/customer/listCustomer')),
    exact: true,
  },
  {
    path: '/window/list/:id',
    Component: loadable(() => import('pages/CW/products/catalogue/listCatalogue')),
    exact: true,
  },
  {
    path: '/window/list/:id',
    Component: loadable(() => import('pages/CW/products/catalogue/listCatalogue')),
    exact: true,
  },

  // ================= Home End =================== //

  // ================= Inventory-- Stock Transfer Start=================== //

  // {
  //   path:'/stock/stockTransferConfiguration',
  //   Component:loadable(()=>import('pages/CW/inventory/stockTransfer/stockTransferConfiguration/listStockTransferConfiguration')),
  //   exact: true,
  // },

  // {
  //   path:'/stock/createStockTransferConfiguration',
  //   Component:loadable(()=>import('pages/CW/inventory/stockTransfer/stockTransferConfiguration/createStockTransferConfiguration')),
  //   exact: true,
  // },

  {
    path: '/stock/stockTransferRequest',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferRequest/listStockTransferRequest'),
    ),
    exact: true,
  },
  // {
  //   path:'/sales/saleserror/listSalesError',
  //   Component:loadable(()=>import('pages/CW/sales/salesError/listSalesError')),
  //   exact: true,
  // },
  // {
  //   path:'/sales/saleserror/listSalesError',
  //   Component:loadable(()=>import('pages/CW/sales/salesError/listSalesError')),
  //   exact: true,
  // },
  // {
  //   path:'/sales/saleserror/listSalesError',
  //   Component:loadable(()=>import('pages/CW/sales/salesError/listSalesError')),
  //   exact: true,
  // },

  // ================= Inventory-- Stock Transfer End=================== //

  // ============= Sales ======================//
  {
    path: '/sales/saleserror/listSalesError',
    Component: loadable(() => import('pages/CW/sales/salesError/listSalesError')),
    exact: true,
  },
  {
    path: '/sales/saleserror/viewSalesError',
    Component: loadable(() => import('pages/CW/sales/salesError/viewSalesError')),
    exact: true,
  },

  // ============== Sales End ==============//

  // ============= Purchase - Goods Receipt ===== //
  {
    path: '/purchase/newPurchaseOrder',
    Component: loadable(() => import('pages/CW/purchase/purchaseOrder1/newPurchaseOrder')),
    exact: true,
  },
  {
    path: '/stock/stockAllocation',
    Component: loadable(() => import('pages/CW/stockAllocation')),
    exact: true,
  },
  {
    path: '/purchase/goodsReceipts',
    Component: loadable(() => import('pages/CW/purchase/goodsReceipts/listGoodsReceipts')),
    exact: true,
  },
  {
    path: '/purchase/goodsReceipts-batch',
    Component: loadable(() => import('pages/CW/purchase/goodsReceipts/listGoodsReceipts1')),
    exact: true,
  },
  {
    path: '/inventory/inventoryCount',
    Component: loadable(() => import('pages/CW/inventory/inventoryCount')),
    exact: true,
  },
  {
    path: '/inventory/stockWrite',
    Component: loadable(() => import('pages/CW/inventory/stockWrite')),
    exact: true,
  },
  // ============= Purchase - Goods Receipt END===== //

  // ============= Stock Transfer START ============== //
  {
    path: '/stock/listStockTransferRequest',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferRequest/listStockTransferRequest'),
    ),
    exact: true,
  }, {
    path: '/stock/listStockTransferRequest',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferRequest/listStockTransferRequest'),
    ),
    exact: true,
  },
  {
    path: '/stock/createStockTransferRequest',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferRequest/createStockTransferRequest'),
    ),
    exact: true,
  },
  {
    path: '/stock/viewStockTransferRequest',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferRequest/viewStockTransferRequest'),
    ),
    exact: true,
  },

  {
    path: '/stock/listStockTransferOrder',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferOrder/stockTransferOrderList'),
    ),
    exact: true,
  },
  {
    path: '/stock/createStockTransferOrder',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferOrder/createStockTransferOrder'),
    ),
    exact: true,
  },

  {
    path: '/stock/viewStockTransferOrder',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferOrder/viewStockTransferOrder'),
    ),
    exact: true,
  },
  {
    path: '/stock/listStockTransferIssue',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferIssue/stockTransferIssueList'),
    ),
  },
  {
    path: '/stock/viewStockTransferIssue',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferIssue/viewStockTransferIssue'),
    ),
  },
  {
    path: '/stock/listStockTransferReceipt',
    Component: loadable(() =>
      import('pages/CW/inventory/stockTransfer/stockTransferReceipt/stockTransferReceiptList'),
    ),
  },

  // ============= Stock Transfer END ============== //


  // ============= New Stock Transfer START ============== //

  {
    path: '/Stock-Transfer/STIssue',
    Component: loadable(() => import('pages/CW/Stock-Transfer/STIssue/stIssue')),
    exact: true,
  },
  {
    path: '/Stock-Transfer/STIssue-batch',
    Component: loadable(() => import('pages/CW/Stock-Transfer/STIssue/stIssue2')),
    exact: true,
  },
  {
    path: '/Stock-Transfer/STReceipt',
    Component: loadable(() => import('pages/CW/Stock-Transfer/STReceipt/newSTReceipt')),
    exact: true,
  },

  // ============= New Stock Transfer END ============== //


  // ============= PRODUCTION - Production Order START===== //

  {
    path: '/production/listProductionOrder',
    Component: loadable(() => import('pages/CW/production/productionOrder/listProductionOrder')),
    exact: true,
  },
  {
    path: '/production/viewProductionOrder',
    Component: loadable(() => import('pages/CW/production/productionOrder/viewProductionOrder')),
    exact: true,
  },
  {
    path: '/production/createProductionOrder',
    Component: loadable(() => import('pages/CW/production/productionOrder/createProductionOrder')),
    exact: true,
  },
  // ============= Purchase - Production Order END===== //

  // ===================Inventory---- Physical Inventory Start=============================  //
  {
    path: '/CW/inventory/physicalInventory',
    Component: loadable(() => import('pages/CW/inventory/physicalInventory/listPhysicalInventory')),
    exact: true,
  },
  {
    path: '/CW/inventory/physicalInventory/createPhysicalInventory',
    Component: loadable(() =>
      import('pages/CW/inventory/physicalInventory/createPhysicalInventory'),
    ),
    exact: true,
  },
  {
    path: '/CW/inventory/physicalInventory/viewPhysicalInventory',
    Component: loadable(() => import('pages/CW/inventory/physicalInventory/viewPhysicalInventory')),
    exact: true,
  },
  // ===========================Inventory---- Physical Inventory End=============================== //

  // ===========================Inventory---- Goods Movement  Start=============================== //

  {
    path: '/CW/inventory/goodsMovement/listGoodsMovement',
    Component: loadable(() => import('pages/CW/inventory/goodsMovement/listGoodsMovement')),
    exact: true,
  },

  // ===========================Inventory---- Goods Movement  End=============================== //

  // ====================Inventory---Reports Start(Nikhil's Change)============================
  {
    path: '/reports/reportsList/:id',
    Component: loadable(() => import('pages/Reports/reportsList')),
    exact: true,
  },

  // ====================Inventory---Reports End============================

  {
    path: '/CW/setup/company/listCompany',
    Component: loadable(() => import('pages/CW/setup/company/listCompany')),
    exact: true,
  },

  {
    path: '/CW/setup/company/viewCompany',
    Component: loadable(() => import('pages/CW/setup/company/viewCompany')),
    exact: true,
  },
  {
    path: '/CW/setup/company/createCompany',
    Component: loadable(() => import('pages/CW/setup/company/createCompany')),
    exact: true,
  },
  {
    path: '/CW/setup/bussiness/createBusinessUnit',
    Component: loadable(() => import('pages/CW/setup/bussiness/createBusinessUnit')),
    exact: true,
  },
  {
    path: '/CW/setup/bussiness',
    Component: loadable(() => import('pages/CW/setup/bussiness/listBussinessUnit')),
    exact: true,
  },
  {
    path: '/CW/setup/viewBussiness',
    Component: loadable(() => import('pages/CW/setup/bussiness/viewBussiness')),
    exact: true,
  },
  {
    path: '/CW/setup/till',
    Component: loadable(() => import('pages/CW/setup/till/listTill')),
    exact: true,
  },
  {
    path: '/CW/setup/createTill',
    Component: loadable(() => import('pages/CW/setup/till/createTill')),
    exact: true,
  },
  {
    path: '/CW/setup/viewTill',
    Component: loadable(() => import('pages/CW/setup/till/viewTill')),
    exact: true,
  },
  {
    path: '/setup/listUsers',
    Component: loadable(() => import('pages/CW/setup/users/listUsers')),
    exact: true,
  },
  // {
  //   path: '/setup/createUsers',
  //   Component: loadable(() => import('pages/CW/setup/users/createUsers')),
  //   exact: true,
  // },
  {
    path: '/setup/viewUsers',
    Component: loadable(() => import('pages/CW/setup/users/viewUsers')),
    exact: true,
  },
  {
    path: '/CW/profile',
    Component: loadable(() => import('pages/CW/profile')),
    exact: true,
  },
  {
    path: '/setup/listCustomer',
    Component: loadable(() => import('pages/CW/setup/customer/listCustomer')),
    exact: true,
  },
  {
    path: '/setup/viewCustomer',
    Component: loadable(() => import('pages/CW/setup/customer/viewCustomer')),
    exact: true,
  },
  {
    path: '/setup/createCustomer',
    Component: loadable(() => import('pages/CW/setup/customer/createCustomer')),
    exact: true,
  },

  // ==================  Products ============================= //

  {
    path: '/products/productsList',
    Component: loadable(() => import('pages/CW/products/master/listProducts')),
    exact: true,
  },
  {
    path: '/products/createProduct',
    Component: loadable(() => import('pages/CW/products/master/createProducts')),
  },
  {
    path: '/setup/viewProducts',
    Component: loadable(() => import('pages/CW/products/master/viewProducts')),
  },

  // ==================  Products END ============================= //

  {
    path: '/products/productCatalog',
    Component: loadable(() => import('pages/CW/products/catalogue/createCatalogue')),
  },

  {
    path: '/products/listCatalog',
    Component: loadable(() => import('pages/CW/products/catalogue/listCatalogue')),
  },

  // ==================  Product Catalogue END ============================= //
  // home and Dashobard //
  {
    path: '/dashboards/dashboardsList/:id',
    Component: loadable(() => import('pages/Dashboards/dashboardsList')),
    exact: true,
  },

  {
    path: '/import/importList',
    Component: loadable(() => import('pages/CW/importList')),
    exact: true,
  },

  {
    path: '/Security/listrole',
    Component: loadable(() => import('pages/CW/Security/Roles/rolelist')),
    exact: true,
  },
  {
    path: '/Security/createrole',
    Component: loadable(() => import('pages/CW/Security/Roles/createrole')),
    exact: true,
  },

  {
    path: '/Security/updaterole',
    Component: loadable(() => import('pages/CW/Security/Roles/updaterole')),
    exact: true,
  },
  {
    path: '/Security/listuser',
    Component: loadable(() => import('pages/CW/Security/User/userlist')),
    exact: true,
  },
  {
    path: '/Security/newUser',
    Component: loadable(() => import('pages/CW/Security/User/newUser')),
    exact: true,
  },
  {
    path: '/CW/sqltool',
    Component: loadable(() => import('pages/CW/sqltool2/sqltool')),
    exact: true,
  },
  {
    path: '/CW/sqladvancetool',
    Component: loadable(() => import('pages/CW/sqltool2/sqladvancetool')),
    exact: true,
  },
  // OFD Menus By Avinash//
  {
    path: '/ofd/allorders',
    Component: loadable(() => import('pages/OFD/allOrders')),
    exact: true,
  },
  {
    path: '/ofd/cancelled',
    Component: loadable(() => import('pages/OFD/cancelledOrders')),
    exact: true,
  },
  {
    path: '/ofd/completedorders',
    Component: loadable(() => import('pages/OFD/completedOrders')),
    exact: true,
  },
  {
    path: '/ofd/inprogress',
    Component: loadable(() => import('pages/OFD/inprogressOrders')),
    exact: true,
  },
  {
    path: '/ofd/intransit',
    Component: loadable(() => import('pages/OFD/intrasitOrders')),
    exact: true,
  },
  {
    path: '/ofd/notavailable',
    Component: loadable(() => import('pages/OFD/notAvailableOrders')),
    exact: true,
  },
  {
    path: '/ofd/pending',
    Component: loadable(() => import('pages/OFD/pendingOrders')),
    exact: true,
  },
  {
    path: '/ofd/delivery',
    Component: loadable(() => import('pages/OFD/delivery')),
    exact: true,
  },
  {
    path: '/ofd/orderdetails',
    Component: loadable(() => import('pages/OFD/orderDetails')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={() => <Redirect to="/CW/home" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <ErrorBounderies>
                  <Component />
                </ErrorBounderies>
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
