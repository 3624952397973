/* eslint-disable */
import React from 'react'
import { injectIntl } from 'react-intl'
import { Select, Spin } from 'antd'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter, Link } from 'react-router-dom'
import { URL, GENERICURL } from '../../../../pages/app-config/index'
import { changeSelectedOption, changeAutoSearchBar } from '../../../../redux/menu/actions'
import styles from './style.module.scss'

const { Option, OptGroup } = Select;

const mapStateToProps = ({ user, menu }) => {
  return {
    Token: user.token,
    removeAutoSearchBar: menu.removeAutoSearchBar
  }
}



@connect(mapStateToProps)
@injectIntl
class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuDataForService: [],
      fetching: false,
      defaultValueToSet: 'Search...'
    }
  }

  

  componentWillMount = () => {
    const flagforselectedmenu = localStorage.getItem('flagforselectedmenu')
    const selectedmenu = JSON.parse(localStorage.getItem('selectedmenu'))
    const roleId = localStorage.getItem('roleId')
    let selectedMenus=JSON.parse(selectedmenu);
    if (flagforselectedmenu === '2') {
       const SortChildMenus = selectedMenus.sort(function (a, b) {
        return a.seqno - b.seqno
      }) 
      this.setState({ menuDataForService: SortChildMenus })
    } else {
     
      const newToken = localStorage.getItem('Token')
      const getMenusData = {
        query: `query {
          get360MenuList(applicationId:"0", roleId:"${roleId}"){
            menu
            cwLogo
            clientLogo
          }
      }`,
      }
      axios({
        url: GENERICURL,
        method: 'POST',
        async: true,
        crossDomain: true,
        data: getMenusData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${newToken}`,
        },
      }).then(response => {
        // console.log("=======response in search=======",response)
        const menusData = JSON.parse(response.data.data.get360MenuList.menu);
        const SortChildMenus = menusData.sort(function (a, b) {
          return a.seqno - b.seqno
        })
        this.setState({ menuDataForService: SortChildMenus })
      })
    }
  }

  componentWillReceiveProps = nextProps => {

    if (nextProps.removeAutoSearchBar === true) {
      this.clearAutoSelect();
    }
  }


  toSendRequest = (windowid) => {
    const { dispatch } = this.props;
    if (windowid) {
      dispatch(changeSelectedOption({ selectedOption: windowid }))
     // window.localStorage.setItem('adWinId', windowid);
      window.localStorage.setItem('recordId', undefined);
      dispatch(changeAutoSearchBar({ removeAutoSearchBar: false }))
    }

  }

  toSendRequest1 = (windowid, title,type,url) => {
    const { history } = this.props
    if(type=='Generic'){
    //  window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      window.location = '/#/window/list/'.concat(`${windowid}`)
    }else if(type==='Report'){
    //  window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      const reportUrl = '/reports/reportsList/'.concat(`${windowid}`)
      history.push(`${reportUrl}`)
    }else if(type==='Dashboard'){
   //   window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      const dashboardUrl = '/dashboards/dashboardsList/'.concat(`${windowid}`)
      history.push(`${dashboardUrl}`)
    }else{
      history.push(`${url}`)
    }
   
  }

  toSendRequest = (windowid) => {
   // window.localStorage.setItem('adWinId', windowid)
    const { dispatch } = this.props
    if (windowid) dispatch(changeSelectedOption({ selectedOption: windowid }))
    else console.log('.')
  }


  clearAutoSelect = () => {
    this.setState({ defaultValueToSet: 'Start typing to search...' })
  }

  render() {
    const { menuDataForService, fetching, defaultValueToSet } = this.state;
    const sideMenu=window.localStorage.getItem('isSideMenu')
    // console.log("=====sideMenu=========",sideMenu)
    const menuList = menuDataForService.map(menu => {
      const childMenus = menu.children
      if (childMenus === undefined) {
        return (
          <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
            <Option
              key={menu.id}
              value={menu.title}
              onClick={() => this.toSendRequest1(menu.id, menu.title,menu.type,menu.url)}
            >
              <Link>
                <span style={{ color: '#314659' }}>{menu.title}</span>
              </Link>
            </Option>
          </OptGroup>
        )
      } else {
        const SortChildMenus = menu.children.sort(function (a, b) {
          return a.seqno - b.seqno
        })
        return (
          <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
            {SortChildMenus.map(sub => {
              return (
                <Option
                  key={sub.id}
                  value={sub.title}
                  onClick={() => this.toSendRequest1(sub.id, sub.title,sub.type,sub.url)}
                >
                  <Link>
                    <span style={{ color: '#314659' }}>{sub.title}</span>
                  </Link>
                </Option>
              )

            })}
          </OptGroup>
        )

      }

    })
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <Select
              showSearch
              value={<span><span><img style={{color:"#929090"}}
                src='resources/images/searchImage.svg'
              />&nbsp;</span><span className={styles.searchBarStyleValue}>{defaultValueToSet}</span></span>}
              allowClear={false}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              showArrow={false}
              // placeholder="Search..."
              style={{ width: sideMenu==="N"?500:200, borderRadius: '5px' }}
            >
              {menuList}
            </Select>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(Search)