export const EnableLogs = false
// Test Urls starts //

/* export const URL = 'https://s20q1.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://s20q1.mycw.in/back-office-2.0/graphql'
export const Auth = 'https://s20q1.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s20q1.mycw.in:8443/' */

// Test Urls ends //

// Dev server Urls starts //

export const URL = 'https://erps-test.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://erps-test.mycw.in/back-office-2.0/graphql'
export const Auth = 'https://identity-test.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://erps-test.mycw.in:8443/'

// Dev server Urls ends //

// Pre prod Urls starts 

// export const URL = 'https://s360app.mycw.in/cw-retail/graphql'
// export const GENERICURL ='https://sapp.mycw.in/services/graphql'
// export const Auth ='https://s360app.mycw.in/cw-identity/oauth/token'
// export const FILEDOWNLOADURL = 'https://sapp.mycw.in:8443/'

// Pre prod Urls ends

// demo server Urls starts
// export const URL = 'https://s2demos.cw.solutions/cw-retail/graphql'
// export const GENERICURL ='https://sdemos.cw.solutions/back-office-2.0/graphql'
// export const Auth =' https://identity-demos.cw.solutions/cw-identity/oauth/token'
// export const FILEDOWNLOADURL = 'https://sdemos.cw.solutions:8443/'


// ofc server Urls starts
/* export const URL = 'https://sofc-test-analytics.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://sofc-test-analytics.mycw.in/back-office-2.0/graphql'
export const Auth = 'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://sofc-test-analytics.mycw.in:8443/' */


export const UserId = '100'
// demo server Urls ends
