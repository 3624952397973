
 const initialState = {
  salesErrorDetails:'' 
} 

export default function tillListView( state = initialState, action) {
if(action.type==='SalesError_Details'){
    return {
      ...state,
      salesErrorDetails:action.payload,
    }
  }
  return state; 

}


