/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import axios from 'axios'
import SearchBar from 'components/layout/TopBar/Search/index'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// import configurationJSON from '../../../pages/clientWindowConfig.json'
import { Layout } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { URL, GENERICURL } from '../../../pages/app-config/index'
import style from './style.module.scss'
import { changeSelectedOption } from '../../../redux/menu/actions'

const { Sider } = Layout
const mapStateToProps = ({ /* menu, */ settings }) => ({
  // menuData: menu.menuData,
  settings,
  flyoutActive:
    (settings.menuType === 'flyout' ||
      settings.menuType === 'compact' ||
      settings.isMenuCollapsed) &&
    !settings.isMobileView,
})

@withRouter
@connect(mapStateToProps)
class MenuLeft extends React.Component {
  state = {
    activeSubmenu: '',
    activeItem: '',
    renderedFlyoutItems: {},
    menuDataForService: [],
    activeToggle: 0,
  }

  flyoutTimers = {}

  currentLocation = ''

  componentWillMount = () => {
    const flagforselectedmenu = localStorage.getItem('flagforselectedmenu')
    const selectedmenu = JSON.parse(localStorage.getItem('selectedmenu'))
    const roleId = localStorage.getItem('roleId')
    let selectedMenus=JSON.parse(selectedmenu);
    if (flagforselectedmenu === '2') {
      const SortChildMenus = selectedMenus.sort(function(a, b) {
        return a.seqno - b.seqno
      }) 
      this.setState({ menuDataForService: SortChildMenus })
    } else {
      const newToken = localStorage.getItem('Token')
      const getMenusData = {
        query: `query {
          get360MenuList(applicationId:"0", roleId:"${roleId}"){
            menu
            cwLogo
            clientLogo
          }
      }`,
      }
      
      axios({
        url: GENERICURL,
        method: 'POST',
        async: true,
        crossDomain: true,
        data: getMenusData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${newToken}`,
        },
      }).then(response => {
        const menusData = JSON.parse(response.data.data.get360MenuList.menu);
        const cwLogoFromServer = response.data.data.get360MenuList.cwLogo
        // console.log("=====cwLogoFromServer in menu left======",cwLogoFromServer)
        this.setState({cwLogoInState:cwLogoFromServer})
        const SortChildMenus = menusData.sort(function(a, b) {
          return a.seqno - b.seqno
        })

        const DrData = SortChildMenus
        const mainArray = []
      const mainTwo = []
      for (let index = 0; index < DrData.length; index++) {
        const elementOne = DrData[index]
        const elementWithChildren = elementOne.children !=undefined ? elementOne.children:''
        const childrenArray = []
        for (let index1 = 0; index1 < elementWithChildren.length; index1++) {
          const elementTwo = elementWithChildren[index1]
          if (elementTwo.type !== 'Report') {
            childrenArray.push(elementTwo)
          }
        }
        mainArray.push({
          title: DrData[index].title,
          key: DrData[index].key,
          icon:DrData[index].icon,
          children: childrenArray,
        })
      }
      for (let index = 0; index < mainArray.length; index++) {
        const element = mainArray[index].children
        if (element.length > 0) {
          mainTwo.push(mainArray[index])
        }
      }
        this.setState({ menuDataForService: mainTwo })
        

      })
    }
  }

  componentDidMount() {
    // this.setActiveItems(this.props)
  }

  getClicked = () =>{
    const {history} = this.props
    history.push('/CW/home')
    window.location.reload()
    window.localStorage.removeItem("flagforselectedmenu");
    window.localStorage.removeItem("selectedmenu");
    const roleId = localStorage.getItem('roleId')    
      const newToken = localStorage.getItem('Token')
      const getMenusData = {
        query: `query {
          get360MenuList(applicationId:"0", roleId:"${roleId}"){
            menu
            cwLogo
            clientLogo
          }
      }`,
      }
      
      axios({
        url: GENERICURL,
        method: 'POST',
        async: true,
        crossDomain: true,
        data: getMenusData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${newToken}`,
        },
      }).then(response => {
        const menusData = JSON.parse(response.data.data.get360MenuList.menu);
        const cwLogoFromServer = response.data.data.get360MenuList.cwLogo
        // console.log("=====cwLogoFromServer in menu left======",cwLogoFromServer)
        this.setState({cwLogoInState:cwLogoFromServer})
        const SortChildMenus = menusData.sort(function(a, b) {
          return a.seqno - b.seqno
        })

        const DrData = SortChildMenus
        const mainArray = []
      const mainTwo = []
      for (let index = 0; index < DrData.length; index++) {
        const elementOne = DrData[index]
        const elementWithChildren = elementOne.children !=undefined ? elementOne.children:''
        const childrenArray = []
        for (let index1 = 0; index1 < elementWithChildren.length; index1++) {
          const elementTwo = elementWithChildren[index1]
          if (elementTwo.type !== 'Report') {
            childrenArray.push(elementTwo)
          }
        }
        mainArray.push({
          title: DrData[index].title,
          key: DrData[index].key,
          icon:DrData[index].icon,
          children: childrenArray,
        })
      }
      for (let index = 0; index < mainArray.length; index++) {
        const element = mainArray[index].children
        if (element.length > 0) {
          mainTwo.push(mainArray[index])
        }
      }
        this.setState({ menuDataForService: mainTwo })
      })
    }
  


  componentWillReceiveProps(newProps) {
    const { pathname } = newProps.location
    if (this.currentLocation !== pathname) {
      this.currentLocation = pathname
    }
  }

  toggleSettings = e => {
    e.preventDefault()
    const { dispatch, settings } = this.props
    const { isSidebarOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  }

  toggleMenu = e => {
    e.preventDefault()
    const { activeToggle } = this.state
    if (activeToggle === 0) {
      this.setState({ activeToggle: 1 })
      window.localStorage.setItem('hideLeftMenuThreeLines',true)
    } else {
      this.setState({ activeToggle: 0 })
      window.localStorage.setItem('hideLeftMenuThreeLines',false)
    }
    const { dispatch, settings } = this.props
    const { isMenuCollapsed } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  toggleMobileMenu = e => {
    e.preventDefault()
    const { dispatch, settings } = this.props
    const { isMobileMenuOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  handleSubmenuClick = (e, key) => {
    e.preventDefault()
    const { activeSubmenu } = this.state
    const { flyoutActive } = this.props
    if (flyoutActive) {
      return
    }
    this.setState({
      activeSubmenu: activeSubmenu === key ? '' : key,
    })
  }

  handleFlyoutOver = (event, key, items) => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      clearInterval(this.flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      const element = this.renderFlyoutMenu(items, key, itemDimensions)
      this.setState(state => ({
        renderedFlyoutItems: {
          ...state.renderedFlyoutItems,
          [key]: element,
        },
      }))
    }
  }

  handleFlyoutOut = key => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      this.flyoutTimers[key] = setTimeout(() => {
        this.setState(state => {
          delete state.renderedFlyoutItems[key]
          return {
            renderedFlyoutItems: {
              ...state.renderedFlyoutItems,
            },
          }
        })
      }, 100)
    }
  }

  handleFlyoutContainerOver = key => {
    clearInterval(this.flyoutTimers[key])
  }

  toSendRequest1 = (windowid, title,type,url) => {
    const { history } = this.props
    if(type=='Generic'){
     // window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      let genericUrl = '/window/list/'.concat(`${windowid}`)
      history.push(`${genericUrl}`)
    }else if(type==='Report'){
    //  window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      // const reportUrl = url
      const reportUrl = '/reports/reportsList/'.concat(`${windowid}`)
      history.push(`${reportUrl}`)
    }else if(type==='Dashboard'){
    //  window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      const dashboardUrl = '/dashboards/dashboardsList/'.concat(`${windowid}`)
      history.push(`${dashboardUrl}`)
    }
    else{
    //  window.localStorage.setItem('adWinId', windowid);
      this.toSendRequest(windowid)
      this.setState({ defaultValueToSet: title })
      const customUrl = url
      history.push(`${customUrl}`)
    }
  }

  toSendRequest = (windowid, key) => {
  //  window.localStorage.setItem('adWinId', windowid)
    this.setState({ activeItem: key })
    const { dispatch } = this.props
    if (windowid) dispatch(changeSelectedOption({ selectedOption: windowid }))
    else console.log('.')
  }

  renderFlyoutMenu = (items, key, itemDimensions) => {
    const { settings } = this.props
    const { activeItem } = this.state
    // const left = `${itemDimensions.left + itemDimensions.width - 10}px`
    const top = `${itemDimensions.top}px`

    return (
      <div
        style={{ left: '80px', top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutLeft]: settings.menuLayoutType === 'left',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
        key={key}
      >
        <ul
          className={style.air__menuLeft__list}
          onMouseEnter={() => this.handleFlyoutContainerOver(key)}
          onMouseLeave={() => this.handleFlyoutOut(key)}
        >
          {items.map(item => {
            return (
              <li
                className={classNames(style.air__menuLeft__item, {
                  [style.air__menuLeft__item__active]: activeItem === item.key,
                })}
                key={item.key}
              >
                <Link to={item.url} className={style.air__menuLeft__link}>
                  {item.icon && <i className={`${item.icon} ${style.air__menuLeft__icon}`} />}
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  setActiveItems = props => {
    // const { menuDataForService = [] } = props
    const { menuDataForService } = this.state
    if (!menuDataForService.length) {
      return
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const activeItem =
      _.find(flattenItems(menuDataForService, 'children'), ['url', props.location.pathname]) || {}
    const activeSubmenu = menuDataForService.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === activeItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })
    this.setState({
      activeItem: activeItem.key,
      activeSubmenu: activeSubmenu.key,
    })
  }

  generateMenuItems = () => {
    // const { menuData = [] } = this.props
    const { activeSubmenu, activeItem } = this.state
    const { menuDataForService = [] } = this.state
    const menuItem = item => {
      const { key, title, icon, url } = item
      if (item.category) {
        return (
          <li className={style.air__menuLeft__category} key={Math.random()}>
            <span>{title}</span>
          </li>
        )
      }
      return (
        <li
          className={classNames(style.air__menuLeft__item, {
            [style.air__menuLeft__item__active]: activeItem === key,
          })}
          key={key}
        >
          {item.url && (
            <Link
              className={style.air__menuLeft__link}
              onClick={() => this.toSendRequest1(item.id, item.title,item.type,item.url)}
              role="presentation"
            >
              {icon && <i className={`${icon} ${style.air__menuLeft__icon}`} />}
             
               <span>{title}</span>
            </Link>
          )}
          {!item.url && (
            <a href="#" onClick={e => e.preventDefault()} className={style.air__menuLeft__link}>
              {icon && <i className={`${icon} ${style.air__menuLeft__icon}`} />}
              <span>{title}</span>
            </a>
          )}
        </li>
      )
    }

    const submenuItem = item => {
      const SortChildMenus = item.children.sort(function(a, b) {
        return a.seqno - b.seqno
      })

      return (
        <li
          className={classNames(style.air__menuLeft__item, style.air__menuLeft__submenu, {
            [style.air__menuLeft__submenu__active]: activeSubmenu === item.key,
          })}
          key={item.key}
        >
          <a
            href="#"
            className={style.air__menuLeft__link}
            onClick={e => this.handleSubmenuClick(e, item.key)}
            onMouseEnter={event => this.handleFlyoutOver(event, item.key, item.children)}
            onFocus={event => this.handleFlyoutOver(event, item.key, item.children)}
            onMouseLeave={() => this.handleFlyoutOut(item.key)}
            onBlur={() => this.handleFlyoutOut(item.key)}
          >
            <i className={`${item.icon} ${style.air__menuLeft__icon}`} />
            <span>{item.title}</span>
            {item.count && (
              <span className="badge text-white bg-blue-light float-right mt-1 px-2">
                {item.count}
              </span>
            )}
          </a>
          <ul className={style.air__menuLeft__list}>
            {SortChildMenus.map(sub => {
              if (sub.children) {
                return submenuItem(sub)
              }
              return menuItem(sub)
            })}
          </ul>
        </li>
      )
    }

    return menuDataForService.map(item => {
      if (item.children) {
        return submenuItem(item)
      }

      return menuItem(item)
    })
  }

  menuIconClicked=()=>{
    // window.localStorage.setItem('hideLeftMenuThreeLines',true)
    /* const hideLeftMenuThreeLines = window.localStorage.getItem('hideLeftMenuThreeLines')
    if(hideLeftMenuThreeLines){
      window.localStorage.setItem('hideLeftMenuThreeLines',false)
    } */
  }

  render() {

    const { settings } = this.props
    const { renderedFlyoutItems,cwLogoInState } = this.state
    const cwLogoFromLocalStorage = window.localStorage.getItem('cwLogo')
    // console.log("=====cwLogoFromServer in menu left======",cwLogoInState)

    const items = this.generateMenuItems()
    // console.log("window.localStorage.getItem('CW360UIConfigurations')======>",window.localStorage.getItem('CW360UIConfigurations'))
    const clientConfigurations = JSON.parse(window.localStorage.getItem('CW360UIConfigurations'))
    return (
      <Sider width="auto">
        <TransitionGroup>
          {Object.keys(renderedFlyoutItems).map(item => {
            return (
              <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
                {renderedFlyoutItems[item]}
              </CSSTransition>
            )
          })}
        </TransitionGroup>
        <div
          className={classNames(style.air__menuLeft, {
            [style.air__menuLeft__mobileToggled]: settings.isMobileMenuOpen,
            [style.air__menuLeft__toggled]: settings.isMenuCollapsed,
            [style.air__menuLeft__unfixed]: settings.isMenuUnfixed,
            [style.air__menuLeft__shadow]: settings.isMenuShadow,
            [style.air__menuLeft__flyout]: settings.menuType === 'flyout',
            [style.air__menuLeft__compact]: settings.menuType === 'compact',
            [style.air__menuLeft__blue]: settings.menuColor === 'blue',
            [style.air__menuLeft__white]: settings.menuColor === 'white',
            [style.air__menuLeft__gray]: settings.menuColor === 'gray',
            [style.air__menuFlyout__black]:
              settings.flyoutMenuColor === 'dark' && settings.menuType !== 'default',
            [style.air__menuFlyout__white]:
              settings.flyoutMenuColor === 'white' && settings.menuType !== 'default',
            [style.air__menuFlyout__gray]:
              settings.flyoutMenuColor === 'gray' && settings.menuType !== 'default',
          })}
        >
          <div className={style.air__menuLeft__outer}>
            <a
              href="#"
              className={style.air__menuLeft__mobileToggleButton}
              onClick={this.toggleMobileMenu}
            >
              <span />
            </a>
            <a href="#" className={style.air__menuLeft__toggleButton} onClick={this.toggleMenu}>
              <img
                src="resources/images/Menuicon.svg"
                alt="Menuicon"
                onClick={this.menuIconClicked}
              />
            </a>
            <a
              href="/CW/home"
              onClick={e => e.preventDefault()}
              className={style.air__menuLeft__logo}
              style={{ backgroundColor: clientConfigurations.topBarColor }}
            >
              <span
                style={{
                  objectFit: 'cover',
                  maxWidth: '120px',
                  maxHeight: '120px',
                  marginTop: '4px',
                }}
              >
                <img
                  className={style.hide}
                  // width="900" alt="" style="max-width:500px; height:auto; max-height:600px;"
                  style={{
                    objectFit: 'cover',
                    maxWidth: '120px',
                    maxHeight: '120px',
                    marginTop: '4px',
                  }}
                  src={
                    cwLogoInState === null || cwLogoInState === undefined
                      ? 'resources/images/cwsolutionswhite.png'
                      : `data:image/jpeg;base64,${
                        cwLogoInState === null || cwLogoInState === undefined
                          ? cwLogoFromLocalStorage
                          : cwLogoInState
                      }`
                  }
                  // src="resources/images/CW360.png"
                  alt="CW"
                  onClick={this.getClicked}
                />
              </span>
              {/* <span>
                <img
                  style={{ width: '40px', height: '40px' }}
                  src="resources/images/ofc.png"
                  alt="CW"
                />
              </span> */}
            </a>
            <Scrollbars
              autoHide
              // style={{height:'89.6%',borderBottom:'8px solid #0c4da9'}}
              renderThumbVertical={({ ...props }) => (
                <div
                  {...props}
                  style={{
                    width: '5px',
                    borderRadius: 'inherit',
                    backgroundColor: 'rgba(195, 190, 220, 0.4)',
                    left: '1px',
                  }}
                />
              )}
            >
              <div className={style.air__menuLeft__user}>
                <div
                  className={style.air__menuLeft__user__name}
                  style={{ margin: '0px', padding: '1px' }}
                >
                  <SearchBar />
                </div>
              </div>
              {
                <div
                  id="menu-left-container"
                  className={style.air__menuLeft__container}
                  style={{ height: '100%' }}
                >
                  <ul className={style.air__menuLeft__list}>{items}</ul>
                </div>
              }
            </Scrollbars>
          </div>
        </div>
        <a href="#" className={style.air__menuLeft__backdrop} onClick={this.toggleMobileMenu} />
      </Sider>
    )
  }
}

export default MenuLeft
