

const initialState = {
  windowDetails: '',
  wysiwygeditorData: '',
  updateWysiwyEditorData: false
}

export default function productListView(state = initialState, action) {
  if (action.type === 'DISPLAY_WINDOW_DETAILS') {
    return {
      ...state,
      records: action.payload,
      windowDetails: action.payload

    }
  }

  if (action.type === 'SEND_WYSIWYGEDITOR_DATA') {
    return {
      ...state,
      wysiwygeditorData: action.payload.sendWysiwygEditorData

    }
  }

  if (action.type === 'REMOVE_REDUX_WYSIWYGEDITOR_DATA') {
    return {
      ...state,
      wysiwygeditorData: action.payload.removeReduxWysiwygEditorData
    }
  }

  if (action.type === 'UPDATEWYSIWYEDITORDATA') {
    return {
      ...state,
      updateWysiwyEditorData: action.payload.updateWysiwyEditorData
    }
  }





  return state
}