
 const initialState = {
  companyDetails:'' 
} 

export default function productListView( state = initialState, action) {
if(action.type==='Company_Details'){
    return {
      ...state,
      companyDetails:action.payload,
    }
  }
  return state; 

}


