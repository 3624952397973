/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col, Dropdown } from 'antd';
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import UserMenu from '../TopBar/UserMenu'
import { changeSelectedOption } from '../../../redux/menu/actions'
import Search from '../TopBar/Search'
import style from './style.module.scss'
import './ExtraStyle.css'

const mapStateToProps = ({ menu, settings }) => ({
  menuData: menu.menuData,
  settings,
  flyoutActive: !settings.isMobileView,
})

@withRouter
@connect(mapStateToProps)
class MenuTop extends React.Component {
  state = {
    activeSubmenu: '',
    activeItem: '',
    renderedFlyoutItems: {}
  }

  flyoutTimers = {}

  currentLocation = ''

  componentDidMount() {
   // this.setActiveItems(this.props)
  }

  componentWillReceiveProps(newProps) {
    const { pathname } = newProps.location
    if (this.currentLocation !== pathname) {
     
     
     // this.setActiveItems(newProps)
      this.currentLocation = pathname
    }
  }

  toggleSettings = () => {
    const { dispatch, settings } = this.props
    const { isSidebarOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  }

  toggleMenu = () => {
    const { dispatch, settings } = this.props
    const { isMenuCollapsed } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  toggleMobileMenu = e => {
    e.preventDefault()
    const { dispatch, settings } = this.props
    const { isMobileMenuOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })

  }

  handleSubmenuClick = (e, key) => {
    const { activeSubmenu } = this.state;
   
    e.preventDefault()
   
    this.setState({
      activeSubmenu: activeSubmenu === key ? key : key,
    })
  }

  handleFlyoutOver = (event, key, items) => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      clearInterval(this.flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      const element = this.renderFlyoutMenu(items, key, itemDimensions)
      this.setState(state => ({
        renderedFlyoutItems: {
          ...state.renderedFlyoutItems,
          [key]: element,
        },
      }))
    }
  }

  handleFlyoutOut = key => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      this.flyoutTimers[key] = setTimeout(() => {
        this.setState(state => {
          delete state.renderedFlyoutItems[key]
          return {
            renderedFlyoutItems: {
              ...state.renderedFlyoutItems,
            },
          }
        })
      }, 100)
    }
  }

  handleFlyoutConteinerOver = key => {
    clearInterval(this.flyoutTimers[key])
  }

  renderFlyoutMenu = (items, key, itemDimensions) => {
    const { settings } = this.props
    const { activeItem } = this.state
    const left = `${itemDimensions.left + itemDimensions.width / 2}px`
    const top = `${itemDimensions.top + itemDimensions.height}px`

    return (
      <div
        style={{ left, top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutTop]: settings.menuLayoutType === 'top',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
        key={key}
      >
        <ul
          className={style.air__menuTop__list}
          onMouseEnter={() => this.handleFlyoutConteinerOver(key)}
          onMouseLeave={() => this.handleFlyoutOut(key)}
        >
          {items.map(item => {
            return (
              <li
                className={classNames(style.air__menuTop__item, {
                  [style.air__menuTop__item__active]: activeItem === item.key,
                })}
                key={item.key}
              >
                <Link to={item.url} className={style.air__menuTop__link}>
                  <span onClick={() => this.toSendRequest(item.windowid, key)} role="presentation">{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  setActiveItems = props => {
    const { menuData = [] } = props
    if (!menuData.length) {
      return
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const activeItem =
      _.find(flattenItems(menuData, 'children'), ['url', props.location.pathname]) || {}
    const activeSubmenu = menuData.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === activeItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })
    // console.log(activeItem.key,"activeItem.key",activeSubmenu.key,"activeSubmenu.key")
    this.setState({
      activeItem: activeItem.key,
      activeSubmenu: activeSubmenu.key,
    })
  }


  toSendRequest = (windowid, key) => {
    console.log("====>To send request function<====", "Window id is====>", windowid, "=====key is======", key)
    this.setState({activeItem:key})
    const { dispatch } = this.props;
    console.log(this.props, "props===>")
    if (windowid) dispatch(changeSelectedOption({ selectedOption: windowid }))
    else console.log("No Window")
  }

  generateMenuItems = () => {
    const { menuData = [] } = this.props
    console.log(menuData, "MenuData===>>>>>>>//>>>>")
    const { activeSubmenu, activeItem } = this.state;

    const submenuItem = item => {
      console.log(activeItem,"activeItem===>>>>>")
      const content = (
        <div>
          <div style={{ width: '100em', marginLeft: '9em', marginTop: '15px' }}>
            <Card style={{boxShadow:"0px -3px 0px -1px #1155cc"}}>
              <div className="site-card-wrapper" style={{margin:'-15px -15px -15px',marginBottom:'0px'}}>
                <Row gutter={16}>
                  {item.children.map(fields => {
                    if (fields.children !== undefined) {
                      return (
                        <Col span={6}>
                          <Card bordered={false}>
                            <span style={{ fontSize: '13px',position:'relative',top:'-15px',color:'black',left:'2px'}}><strong>{fields.title}</strong></span>
                            {fields.children.map(sub => {
                              return (
                                <div>
                                  <li
                                    className={classNames(style.subLink, {[style.subLink__active]: activeItem === sub.key})}
                                    key={sub.key}
                                    style={{ marginLeft: '-25px' ,width:'100%',position: 'relative',top: '-7px'}}
                                  >
                                    <Link to={sub.url}>
                                      <span
                                        onClick={() => this.toSendRequest(sub.windowid, sub.key)}
                                        role="presentation"
                                        className={classNames(style.subLink, {[style.subLink__active]: activeItem === sub.key})}
                                        key={sub.key}
                                       // className={style.subLink} 
                                      >
                                        {sub.title}
                                      </span>
                                    </Link>
                                  </li>
                                </div>
                              )
                            })}
                          </Card>    
                        </Col>
                      )
                    } 
                      return (
                        <Col span={6}>
                          
                          <Card bordered={false}>
                            <span style={{fontSize: '13px',position:'relative',top:'-15px',color:'black',left:'2npx' }}><strong>{fields.title}</strong></span>
                            <div style={{marginTop:'1px'}}>
                              <li
                                className={classNames(style.subLink, {[style.subLink__active]: activeItem === fields.key})}
                                key={fields.key}
                                style={{ marginLeft: '-25px' ,width:'100%',position: 'relative',top: '-7px' }}
                              >
                                <Link to={fields.url}>
                                  <span 
                                    onClick={() => this.toSendRequest(fields.windowid, fields.key)}   
                                    role="presentation"
                                    className={classNames(style.subLink, {[style.subLink__active]: activeItem === fields.key})}
                                    key={fields.key}
                                  >
                                    {fields.title}
                                  </span>
                                </Link>
                              </li>
                            </div>
                          </Card>
                        </Col>
                      )

                  })}
                  
                </Row>
               
              </div>
            </Card>
          </div>
        </div>

      );

      return (
        <li
          className={classNames(style.air__menuTop__item, style.air__menuTop__submenu, {
            [style.air__menuTop__submenu__active]: activeSubmenu === item.key,
          })}
          key={item.key}
          style={{margin:'0px 15px 0px 0px'}}
        >
          <a
            href="#"
            className={style.air__menuTop__link}
            onClick={e => this.handleSubmenuClick(e, item.key)}
         
          >
            <Dropdown overlay={content} trigger="hover"><span>{item.title}</span></Dropdown>
          </a>
          <ul className={style.air__menuTop__list}>
            {item.children.map(sub => {
              console.log(sub, "Sub Menu===>>>>>>>>>children")
              if (sub.children) {
                return submenuItem(sub)
              }
              
              return ""
            })}
          </ul>

        </li>
      )
    }

    return menuData.map(item => {

     
      return submenuItem(item)
    })
  }

  hello = () => {
    const { history } = this.props
    history.push('/CW/home')
  }

  render() {
    const { settings } = this.props
    const { renderedFlyoutItems } = this.state
    const items = this.generateMenuItems()
    return (
      <div>
        <TransitionGroup>
          {Object.keys(renderedFlyoutItems).map(item => {
            return (
              <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
                {renderedFlyoutItems[item]}
              </CSSTransition>
            )
          })}
        </TransitionGroup>
        <div
          className={classNames(style.air__menuTop, style.menuBar, {
            [style.air__menuTop__mobileToggled]: settings.isMobileMenuOpen,
            [style.air__menuTop__shadow]: settings.isMenuShadow,
            [style.air__menuTop__flyout]: true,
            [style.air__menuTop__blue]: settings.menuColor === 'white',
            [style.air__menuTop__white]: settings.menuColor === 'white',
            [style.air__menuTop__gray]: settings.menuColor === 'white',
            [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'white',
            [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
            [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
          })}
        >
          <div className={style.air__menuTop__outer}>
            <a
              href="#"
              className={style.air__menuTop__mobileToggleButton}
              onClick={this.toggleMobileMenu}
            >
              <span />
            </a>
            <a href="#" onClick={e => e.preventDefault()} className={style.air__menuTop__logo}>
              <img src="resources/images/CW_LOGO.jpg" alt="CW ERP" onClick={this.hello} onKeyDown={this.handleKeyDown} role="presentation" />
            </a>
            <div className={style.logo} style={{ position: 'relative', left: '-4em', paddingTop: '1%' }}>
              {/* <div className={style.logoContainer}>
                <Search />
              </div> */}
            </div>
            <div id="menu-left-container" className={style.air__menuTop__container} style={{ paddingTop: '1.20rem', marginLeft: 'auto' }}>
              <ul className={style.air__menuTop__list}>
                {items}

              </ul>
            </div>
            <div style={{ marginTop: '1%', float: 'right' }}>
              <UserMenu />
            </div>
          </div>

        </div>
        <a href="#" className={style.air__menuTop__backdrop} onClick={this.toggleMobileMenu} />
      </div>
    )
  }
}

export default MenuTop
