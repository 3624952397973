
import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  loading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
      case actions.TOKEN:
        return { ...state,token:action.payload[0]['token'],authorized:action.payload[0]['flag'],email:action.payload[0]['email'] }
      default:
        return state
    }
}
